import { defineStore } from 'pinia'
import { countryApi } from '@/api'

export const useCountryStore = defineStore({
  id: 'country',
  state: () => ({
    countries: [], // 用于存储国家列表
    expiresAt: 0
  }),
  getters: {
    isCountryExpired(state) {
      if (!state.countries.length) {
        return true
      }

      const currentTime = Math.floor(Date.now() / 1000) // 当前时间（秒）
      return state.expiresAt < currentTime
    }
  },
  actions: {
    async fetchCountries() {
      try {
        const response = await countryApi.countryList()

        this.countries = response.data

        const expiresAt = Math.floor(Date.now() / 1000) + 3600 // 计算过期时间
        this.expiresAt = expiresAt
      } catch (error) {
        console.error('获取countries失败', error)
      }
    },
    async getCountries() {
      if (this.isCountryExpired) {
        await this.fetchCountries()
      }
      return this.countries
    },
    async getCountryByCode(code: string) {
      if (this.isCountryExpired) {
        await this.fetchCountries()
      }
      return this.countries.find((country) => (country as any).code === code)
    }
  },
  persist: {
    enabled: true,
    H5Storage: window?.localStorage
  }
})

export default useCountryStore
