import http from '../http'

function login(username: string, password: string) {
  const config = {
    url: 'authorizations',
    method: 'post', // 适应您的请求类型
    data: {
      username,
      password
    },
    __isGuestRequest: true // 手动添加 __isGuestRequest 参数
  }

  return http(config)
}

function refresh() {
  const config = {
    url: 'authorizations',
    method: 'put', // 适应您的请求类型
    __isRefreshToken: true // 手动添加 __isRefreshToken 参数
  }
  return http(config)
}

// logout
function logout() {
  return http.delete('authorizations')
}

export default {
  login,
  logout,
  refresh
}
