import http from '../http'

// 商品列表
function countryList() {
  return http.get('countries')
}

export default {
  countryList
}
