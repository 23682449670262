import { defineStore } from 'pinia'

import { authApi, userApi } from '@/api'

let promise: any = null

const useAuthStore = defineStore('auth', {
  // arrow function recommended for full type inference
  state: () => ({
    // all these properties will have their type inferred automatically
    user: null,
    token: '',
    tokenType: ''
  }),
  getters: {
    isLoggedIn(): any {
      return !!this.token
    }
  },
  actions: {
    async login(userInfo: { username: string; password: string }) {
      const { username, password } = userInfo

      try {
        const response = await authApi.login(username, password)

        // update pinia state
        this.token = response.token
        this.tokenType = response.token_type

        await this.getUserInfo()
        // redirect to previous url or default to home page
        // uni.navigateBack()
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },

    async getUserInfo() {
      try {
        // 使用已经获取的 token 发送请求获取用户信息
        const response = await userApi.getCurrentUser()

        console.log(response)

        // 更新用户信息
        this.user = response
      } catch (error) {
        console.error('Failed to fetch user info:', error)
      }
    },
    async logout() {
      try {
        await authApi.logout()

        this.token = ''
        this.tokenType = ''
        this.user = null
      } catch (error) {
        console.log(error)
      }
    },
    refreshToken() {
      if (promise) {
        return promise
      }

      promise = new Promise((resolve) => {
        authApi
          .refresh()
          .then((res) => {
            console.log(res)

            // @ts-ignore
            this.token = res.token
            // @ts-ignore
            this.tokenType = res.token_type

            resolve(true)
          })
          .catch((error) => {
            console.log(error)
            resolve(false)
          })
      })

      promise.finally(() => {
        promise = null
      })

      return promise
    },
    async resetData() {
      this.token = ''
      this.tokenType = ''
      this.user = null
    }
  },
  persist: {
    enabled: true,
    H5Storage: window?.localStorage
  }
})

export default useAuthStore
