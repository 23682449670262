import http from '../http'

export type FAVORITE = {
  id: number
  name: string
  favorite_type: string
  user_id: number
  created_at: string
  updated_at: string
}

// 收藏列表
function favoriteList(page: Number, pageSize: Number) {
  return http.get('favorites', {
    params: {
      page,
      page_size: pageSize
    }
  })
}

// store
function favoriteStore(data: any) {
  return http.post('favorites', data)
}

// favoriteProduct
function favoriteProduct(data: any) {
  return http.put(`favorites/${data.favorite_id}/products/${data.product_id}`)
}

// productList
function favoriteProductList(data: any) {
  return http.get(`favorites/${data.favorite_id}/products`, {
    params: {
      page: data.page,
      page_size: data.pageSize
    }
  })
}

// deleteFavorite
function deleteFavorite(data: any) {
  return http.delete(`favorites/${data.favorite_id}/products/${data.product_id}`)
}

// favoriteProductExport
function favoriteProductExport(id: number) {
  return http.post(`favorites/${id}/products/excel`)
}

// favoriteDelete
function favoriteDelete(id: number) {
  return http.delete(`favorites/${id}`)
}

export default {
  favoriteList,
  favoriteStore,
  favoriteProduct,
  favoriteProductList,
  deleteFavorite,
  favoriteProductExport,
  favoriteDelete
}
