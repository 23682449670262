import http from '../http'

// 供应商列表
function getStsToken() {
  return http.post('sts_tokens')
}

export default {
  getStsToken
}
