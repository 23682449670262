import http from '../http'

/**
 * 获取验证码
 * @param phone 手机号
 */
function getCurrentUser() {
  return http.get('user')
}

export default {
  getCurrentUser
}
