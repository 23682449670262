import { defineStore } from 'pinia'
import { favoriteApi } from '@/api'

export const useFavoriteStore = defineStore({
  id: 'favorite',
  state: () => ({
    favorites: [], // 用于存储国家列表
    expiresAt: 0
    // isLoaded: false
  }),
  getters: {
    isFavoriteExpired(state) {
      if (!state.favorites.length) {
        return true
      }

      const currentTime = Math.floor(Date.now() / 1000) // 当前时间（秒）
      return state.expiresAt < currentTime
    }
  },
  actions: {
    async fetchFavorites() {
      try {
        const response = await favoriteApi.favoriteList(0, 5)

        this.favorites = response.data

        // const expiresAt = Math.floor(Date.now() / 1000) + 10 // 计算过期时间
        // this.expiresAt = expiresAt
      } catch (error) {
        console.error('获取countries失败', error)
      }
    },
    async getFavorites() {
      // if (this.isFavoriteExpired) {
      await this.fetchFavorites()
      // }
      return this.favorites
    }
  },
  persist: {
    enabled: true,
    H5Storage: window?.localStorage
  }
})

export default useFavoriteStore
