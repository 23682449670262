// src/store/auth.ts (示例文件名)

import { defineStore } from 'pinia'
import { stsApi } from '@/api'

export const useStsStore = defineStore({
  id: 'sts',
  state: () => ({
    stsInfo: {
      accessKeyId: '',
      accessKeySecret: '',
      stsToken: '',
      region: '',
      bucket: '',
      expiresAt: 0,
      expiration: 0
    } // 用于存储STS凭证
  }),
  getters: {
    isSTSExpired(state) {
      if (!state.stsInfo) {
        return true // 如果STS信息为空，认为已过期
      }

      const currentTime = Math.floor(Date.now() / 1000) // 当前时间（秒）
      return state.stsInfo.expiresAt < currentTime
    }
  },
  actions: {
    async fetchSTSInfo() {
      try {
        // 发起请求获取STS凭证，并存储到state中
        const response = await stsApi.getStsToken()

        this.stsInfo.accessKeyId = response.access_key_id
        this.stsInfo.accessKeySecret = response.access_key_secret
        this.stsInfo.stsToken = response.sts_token
        this.stsInfo.region = response.region
        this.stsInfo.bucket = response.bucket
        this.stsInfo.expiration = response.expiration

        // {
        //   AccessKeyId: 'STS.xxx',
        //   AccessKeySecret: 'xxx',
        //   StsToken: 'xxx',
        //   Region: 'oss-cn-beijing',
        //   Bucket: 'easygo-server',
        //   Expiration: 3600
        // }

        const expiresAt = Math.floor(Date.now() / 1000) + (this.stsInfo?.expiration ?? 0) // 计算过期时间
        this.stsInfo.expiresAt = expiresAt
      } catch (error) {
        console.error('获取STS凭证失败', error)
      }
    },
    async getSTS() {
      if (this.isSTSExpired) {
        await this.fetchSTSInfo()
      }
      return this.stsInfo
    }
  },
  persist: {
    enabled: true,
    H5Storage: window?.localStorage
  }
})

export default useStsStore
