// @ts-ignore
import buildURL from 'axios/lib/helpers/buildURL'
import type { AxiosRequestConfig } from 'axios'

type ParamsSerializer = AxiosRequestConfig['paramsSerializer']

export function getFullURL(
  baseURL: string,
  url: string,
  params: Record<string, any>,
  paramsSerializer?: ParamsSerializer
) {
  if (url.startsWith('http')) {
    return buildURL(url, params, paramsSerializer)
  }
  const baseURLNew = baseURL.endsWith('/') ? baseURL : `${baseURL}/`
  const urlNew = url.startsWith('/') ? url.slice(1) : url
  return buildURL(`${baseURLNew}${urlNew}`, params, paramsSerializer)
}

export function isRefreshRequest(config: any) {
  // eslint-disable-next-line no-underscore-dangle
  return !!config?.__isRefreshToken
}

export function isGuestRequest(config: any) {
  // eslint-disable-next-line no-underscore-dangle
  return !!config?.__isGuestRequest
}
